import Landing  from './pages/index.vue';
import { NotFoundPage } from "auth_app_vue";

export default [
  {
    path: '/',
    name: 'default',
    redirect: to => {
      let locale = Object.keys(window.envs.locales || {})[0];
      return { name: 'index', params: { locale } };
    },
    children: []
  },
  {
    path: '/:locale',
    name: 'index',
    component: Landing
  },
  {
    path: '/:locale/*',
    name: '404',
    component: NotFoundPage,
  },
  {
    path: '/.*',
    name: '404_',
    component: NotFoundPage,
  },
];
