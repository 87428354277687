<template>
  <ApplicationTemplate>
    <template v-slot:default="{classes}">
      <div :class="classes" class="drive">
        <router-view></router-view>
      </div>
    </template>
  </ApplicationTemplate>
</template>

<script>
  import { ApplicationTemplate } from "auth_app_vue";

  export default {
    data() {
      return {};
    },
    computed: {},
    methods: {},
    components: { ApplicationTemplate },
  }
</script>
